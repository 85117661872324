import React from 'react';
import './styles.css';

const ChipItem = ({ name, color }) => (
  <div className={`chip-item-container ${color}`}>
    <div className="chip-item-content">
      <div className="chip-circle" />
      <span className="chip-text">
        {name}
      </span>
    </div>
  </div>
);

export default React.memo(ChipItem);
