import imgExpertise from '../../../assets/expertise.png';
import imgProductivity from '../../../assets/productivity.png';
import imgRemote from '../../../assets/remote.png';
import imgRising from '../../../assets/rising.png';
import imgWorking from '../../../assets/working.png';
import imgWorkPlace from '../../../assets/workplace.png';

const BENEFIT_LIST = [
  {
    name: 'productivity',
    img: imgProductivity,
    description: 'A space in which high productivity and great camaraderie meet, allowing you to exhibit passion and creativity in a comfortable yet challenging environment.',
  },
  {
    name: 'rising',
    img: imgRising,
    description: 'Not only are we part of a rising industry, but we also have a growing list of customers, allowing us to offer you a steady position and pay in the long term.',
  },
  {
    name: 'expertise',
    img: imgExpertise,
    description: 'We want you to broaden your experience and deepen your expertise, which will be achieved by moving between demanding projects that will keep you amped up.',
  },
  {
    name: 'working',
    img: imgWorking,
    description: 'Fit your working hours to achieve work-life balance, not compromising your project’s objectives and commitments, but actually boosting your standard of work.',
  },
  {
    name: 'workplace',
    img: imgWorkPlace,
    description: 'With us, you are part of a company that represents you in any workplace / team related issues and will back you up for your professional development.',
  },
  {
    name: 'remote',
    img: imgRemote,
    description: 'There is the option to work remotely: when you face an emergency, as a perk, and even as a permanent possibility. Let us know your specific needs!',
  },
];

export {
  BENEFIT_LIST,
};
