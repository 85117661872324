import React from 'react';
import { Grid } from '@material-ui/core';
import MoreBenefitItem from './MoreBenefitItem';
import './styles.css';

const MoreBenefitList = ({ benefits }) => (
  <Grid container className="more-benefit-list">
    {
        benefits.map(({ name }, i) => <MoreBenefitItem name={name} index={i} key={i} />)
      }
  </Grid>
);

export default React.memo(MoreBenefitList);
