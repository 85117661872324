import React from 'react';
import { Grid } from '@material-ui/core';
import './styles.css';

export default () => (
  <Grid container className="loading-div-container">
    <Grid item className="loader-bars">
      <div className="bar" />
      <div className="bar" />
      <div className="bar" />
    </Grid>
  </Grid>
);
