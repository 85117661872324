import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import OpenPositionItem from './OpenPositionItem';
import ModalSeeMore from './ModalSeeMore';

const OpenPositionList = ({ openPositionList }) => {
  const [open, setOpen] = useState(false);
  const [positionSelected, setPositionSelected] = useState({
    description: '',
    information: '',
    location: '',
    more: '',
    title: '',
    vacancy: '',
    vacancyLabel: '',
    languaje: '',
  });

  const handleOpen = (item) => {
    setPositionSelected(item);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid container className="open-position-list">
      {
        openPositionList.map((position, i) => (
          <OpenPositionItem
            {...position}
            key={i}
            handleOpen={handleOpen}
          />
        ))
      }
      <ModalSeeMore position={positionSelected} open={open} handleClose={handleClose} />
    </Grid>
  );
};

export default React.memo(OpenPositionList);
