/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Grid } from '@material-ui/core';
import BenefitItem from './BenefitItem';
import { BENEFIT_LIST } from './helper';
import './styles.css';

const BenefitList = () => (
  <Grid container className="benefit-container">
    <Grid container className="benefit-content">
      {
          BENEFIT_LIST.map(({ name, img, description }, i) => (
            <BenefitItem
              name={name}
              img={img}
              description={description}
              key={i}
            />
          ))
        }
    </Grid>
  </Grid>
);

export default React.memo(BenefitList);
