/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Grid, Typography, Fab } from '@material-ui/core';
import ChipList from './ChipList';
import './styles.css';

const showTextParts = (title, separator) => title.split(separator);
const CITY_COLORS = ['bgRemote', 'bgCityOne', 'bgCityTwo'];

const OpenPositionItem = ({
  description, information, location, more, title, vacancy, languaje, handleOpen,
}) => {
  const [remoteColor, ...restColors] = CITY_COLORS;

  const buildCityChips = (positions) => positions.map(
    (positionName, i) => ({ name: positionName, color: restColors[i] }),
  );

  const buildRemoteArray = (restPositionsString, separator) => {
    const restPositions = showTextParts(restPositionsString, separator);
    if (restPositions.length > 1) {
      const [firstElement, restElement] = restPositions;
      return [[firstElement], [restElement]];
    }
    return [[], restPositions];
  };

  const buildCitiesArray = (restCities, separator) => {
    const restPositions = showTextParts(restCities, separator);
    if (restPositions.length > 1) {
      return [restPositions, []];
    }
    return [[], restPositions];
  };

  const mergeChips = (remote, cityChips) => {
    if (remote.length > 0) {
      const [name] = remote;
      return [{ name, color: remoteColor }, ...cityChips];
    }
    return cityChips;
  };

  const [stringRestPosition] = showTextParts(location, ',');
  const [remote, restLocations] = buildRemoteArray(stringRestPosition, 'or');
  const [stringRestLocation] = restLocations;
  const [cities, singleCity] = buildCitiesArray(stringRestLocation, '/');
  const allCities = [...cities, ...singleCity];
  const cityChips = buildCityChips(allCities);
  const allChips = mergeChips(remote, cityChips);

  const sendData = () => {
    handleOpen({
      title, description, information, allChips, vacancy,
    });
  };

  return (
    <Grid item xs={12} sm={6} className="open-position-item">
      <div className="open-position-content">
        <div variant="body2" className={`languaje ${languaje ? 'bg-languaje' : ''}`}>{languaje || ' '}</div>
        <Typography variant="h5">{title}</Typography>
        <Typography variant="body1">{description}</Typography>
        <ChipList chips={allChips} />
        <div role="button" tabIndex={0} className="more" onClick={sendData}>{more}</div>
        <div className="btn-apply">
          <Fab
            variant="extended"
            size="small"
            color="primary"
            href="mailto:hello@nearshorecode.com"
          >
            APPLY
          </Fab>
        </div>
      </div>
    </Grid>
  );
};

export default React.memo(OpenPositionItem);
