/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Grid, Modal, IconButton, Typography, Fab,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ChipList from '../OpenPositionItem/ChipList';
import './styles.css';

const showTextParts = (title, separator) => title.split(separator);

export default ({
  open, position: {
    information, title, vacancy, languaje, allChips,
  }, handleClose,
}) => {
  const contentInfo = (content, separator) => (content ? showTextParts(content, separator).filter((msg) => msg !== '') : []);
  const [descriptionPositon, aboutPositionContent, aboutYouContent, benfitsContent] = contentInfo(information, '#');
  const descriptionList = contentInfo(descriptionPositon, '\n');
  const [aboutPositionTitle, aboutPositions] = contentInfo(aboutPositionContent, ':');
  const aboutPositionList = contentInfo(aboutPositions, '\n');

  const [aboutYouTitle, aboutYouPositions] = contentInfo(aboutYouContent, ':');
  const aboutYouPositionList = contentInfo(aboutYouPositions, '\n');

  const [benfitsTitle, benfitsPositions] = contentInfo(benfitsContent, ':');
  const benfitsPositionList = contentInfo(benfitsPositions, '\n');

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className="modal-see-more-career"
    >
      <Grid container className="modal-see-more-container">
        <Grid item xs={1} onClick={handleClose} />
        <Grid item xs={10} className="modal-see-more-content">
          <Grid container>
            <Grid item className="btn-close">
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12} className="section-more-info">
              <div variant="body2" className={`languaje ${languaje ? 'bg-languaje' : ''}`}>{languaje || ' '}</div>
              <Grid container className="title-vacancy">
                <Grid item xs={2} sm={1} className="vacancy">
                  <div>{vacancy}</div>
                </Grid>
                <Grid item xs={10} sm={11} className="title">
                  <Typography variant="h5">{title}</Typography>
                </Grid>
              </Grid>
              <ChipList chips={allChips} />
            </Grid>
            <Grid item xs={12} className="section-requirements">
              <Grid container className="requirements-content">
                <Grid item xs={12} className="requirements">
                  {
                    descriptionList.map((description, i) => (
                      <Typography variant="body1" key={i}>
                        {' '}
                        {description}
                        {' '}
                      </Typography>
                    ))
                  }
                </Grid>
                <Grid item xs={12} className="requirements">
                  <Typography variant="body1" className="title">
                    {aboutPositionTitle}
                    {' '}
                  </Typography>
                </Grid>
                <Grid item xs={12} className="requirements-description">
                  {
                    aboutPositionList.map((aboutPosition, i) => (
                      <Typography variant="body1" key={i}>
                        {aboutPosition}
                        {' '}
                      </Typography>
                    ))
                  }
                </Grid>
                <Grid item xs={12} className="requirements">
                  <Typography variant="body1" className="title">
                    {aboutYouTitle}
                    {' '}
                  </Typography>
                </Grid>
                <Grid item xs={12} className="requirements-description">
                  {
                    aboutYouPositionList.map((aboutYou, i) => (
                      <Typography variant="body1" key={i}>
                        {aboutYou}
                        {' '}
                      </Typography>
                    ))
                  }
                </Grid>
                <Grid item xs={12} className="requirements">
                  <Typography variant="body1" className="title">
                    {benfitsTitle}
                    {' '}
                  </Typography>
                </Grid>
                <Grid item xs={12} className="requirements-description">
                  {
                    benfitsPositionList.map((benefit, i) => (
                      <Typography variant="body1" key={i}>
                        {benefit}
                        {' '}
                      </Typography>
                    ))
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className="section-btn">
            <Fab
              variant="extended"
              size="small"
              color="primary"
              href="mailto:hello@nearshorecode.com"
            >
              APPLY
            </Fab>
          </div>
        </Grid>
        <Grid item xs={1} onClick={handleClose} />
      </Grid>
    </Modal>
  );
};
