import React from 'react';
import { Grid } from '@material-ui/core';
import ChipItem from './ChipItem';

const ChipList = ({ chips }) => (
  <Grid container className="chip-list-container">
    {
        chips.map(({ name, color }, i) => <ChipItem name={name} color={color} key={i} />)
      }
  </Grid>
);

export default React.memo(ChipList);
