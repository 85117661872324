import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import './styles.css';

const BenefitItem = ({ name, img, description }) => (
  <Grid item xs={6} sm={4} className="benefit-item-container">
    <img src={img} alt={name} />
    <Typography variant="body1">{description}</Typography>
  </Grid>
);

const MemoBenefitItem = React.memo(BenefitItem);

export default MemoBenefitItem;
