import React from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import './styles.css';

const MoreBenefitItem = ({ name, index }) => (
  <Grid item xs={12} sm={4} className="more-benefit-item">
    <Paper className={`paper-idx-${index}`}>
      <Typography align="center" variant="body1">
        {name}
        {' '}
      </Typography>
    </Paper>
  </Grid>
);

export default React.memo(MoreBenefitItem);
