import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { databaseRef } from '../db/DatabaseRef';
import LoadingContent from '../components/CareersPages/LoadingContent';
import OpenPositions from '../components/CareersPages/OpenPositions';
import Benefits from '../components/CareersPages/Benefits';
import MoreBenefits from '../components/CareersPages/MoreBenefits';
import { SEO } from '../shared/seo';
import Header from '../layout/Header/header';
import Footer from '../layout/Footer/footer';

import '../styles/careerStyles.css';

const overrides = {
  title: 'Careers | Nearshore Code',
  description: 'We are looking for passionate software engineers.',
  openGraph: {
    type: 'website',
    url: 'https://nearshorecode.com/careers',
    title: 'Careers | Nearshore Code',
    description: 'We are looking for passionate software engineers.',
    image: 'https://nearshorecode.com/facebookimage.png',
  },
  twitter: {
    card: 'summary_large_image',
    url: 'https://nearshorecode.com/careers',
    title: 'Careers | Nearshore Code',
    description: 'We are looking for passionate software engineers.',
    image: 'https://nearshorecode.com/twitterimage.png',
  },
};

const MORE_BENEFITS = [
  {
    name: 'Opportunity to share knowledge & work with International teams.',
  },
  {
    name: 'Financial support for training and certification.',
  },
  {
    name: 'Support to become speaker on events.',
  },
];

export default () => {
  const [openPositionList, setOpenPositionList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0 });
    setIsLoading(true);
    const fetchData = databaseRef.child('open');
    fetchData.on('value', (snapshot) => {
      setOpenPositionList(snapshot.val());
      setIsLoading(false);
    }, () => setIsLoading(false));
  }, []);

  return (
    <div>
      <SEO overrides={overrides} />
      <Header />
      <div className="career-component-section">
        <Grid container className="career-component-content">
          <Typography variant="h4" className="title-career">JOIN THE TEAM</Typography>
          <Typography variant="body1" className="title-career-description">Are you a passionate engineer? We are looking for you!</Typography>
          {
            isLoading ? <LoadingContent />
              : <OpenPositions openPositionList={openPositionList} />
          }
          <Typography variant="body1" className="career-creativity-section">
            {' '}
            Creativity, out-of-the-box thinking, quality mindset, collaboration & team-based organization come with a flexible working environment and work-life balance.
            {' '}
          </Typography>
        </Grid>
        <Grid container className="benefit-section">
          <Benefits />
        </Grid>
        <Grid container className="more-benefits-section">
          <MoreBenefits benefits={MORE_BENEFITS} />
        </Grid>
        <Grid container className="remote-positions">
          <Typography variant="h5">
            {' '}
            REMOTE POSITIONS OPEN
            {' '}
          </Typography>
          <Typography>
            No matter where,
            {' '}
            {' '}
            <span className="highlighted">
              International Employees
              {' '}
              {' '}
            </span>
            are welcome
          </Typography>
          <Typography>
            {' '}
            and can actually
            <span className="highlighted">
              {' '}
              {' '}
              WORK REMOTELY
            </span>
            {' '}
            {' '}
            as part of our
          </Typography>
          <Typography>
            {' '}
            company with an HOURLY RATE.
          </Typography>
        </Grid>
      </div>
      <Footer />
    </div>
  );
};
